<template>
  <div>
    <v-card
      flat
      class="rounded-xl"
    >
      <v-row
        align="center"
        justify="center"
      >
        <v-col
          cols="12"
          md="12"
          lg="12"
          class="align-center position-relative mx-auto mt-0 pt-0"
        >
          <v-card
            class="rounded-xl px-5"
            flat
            color="lightGrey"
          >
            <div dir="ltr">
              <v-btn
                icon
                color="primary"
                @click="$router.go(-1)"
              >
                <v-icon>{{ icons.mdiClose }}</v-icon>
              </v-btn>
            </div>
            <div
              :class="$vuetify.breakpoint.smAndDown ? 'px-0 py-7 align-center justify-center' : 'px-10 py-5'"
              class="text-center text-sm-start"
            >
              <!-- title card -->
              <span class="text-h5 primary--text mb-2">
                {{ $t('faq.willHappyToHelpYou') }}
              </span>
              <p class="text-base pt-2">
                {{ $t('faq.notFindAnswerForQa') }}
              </p>

              <!-- contact us btn -->
              <v-btn
                class="mt-4"
                color="primary"
                large
                :href="`mailto:${contactUsEmail}`"
              >
                {{ $t('faq.sendUsEmail') }}
              </v-btn>

              <!-- avatar -->
              <div>
                <v-img
                  width="150"
                  src="@/assets/images/3d-characters/pose-fs-9.png"
                  class="gamification-john-pose-2"
                ></v-img>
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>

      <v-row
        justify="center"
        :class="$vuetify.breakpoint.mdAndDown ? 'mt-5' : 'mt-12'"
      >
        <!-- tabs -->
        <v-col
          cols="11"
          sm="4"
          lg="3"
          md="3"
        >
          <v-tabs
            v-model="selectedTab"
            vertical
            background-color="transparent"
            active-class="primary white--text"
            style="place-content: start;"
          >
            <v-tab
              v-for="item in faqQuestions"
              :key="item.title"
              class="align-self-start"
              style="place-content: start;"
            >
              <v-icon
                right
                size="20"
              >
                {{ item.icon }}
              </v-icon>
              <span class=" text-h7 mx-1">
                {{ $t('faq.' + item.title) }}
              </span>
            </v-tab>
          </v-tabs>
        </v-col>

        <!-- FAQS -->
        <v-col
          cols="11"
          sm="8"
          lg="8"
          md="7"
        >
          <v-window
            class="faq-v-window disable-tab-transition"
          >
            <v-window-item>
              <div class="mb-5 d-flex">
                <v-avatar
                  class="v-avatar-light-bg primary--text"
                  color="primary"
                  size="50"
                  rounded
                >
                  <v-icon
                    color="primary"
                    size="30"
                  >
                    {{ faqQuestions[selectedTab].icon }}
                  </v-icon>
                </v-avatar>
                <div class="ms-3">
                  <h2 class="primary--text text-xl font-weight-medium align-center">
                    {{ $t('faq.' + faqQuestions[selectedTab].title) }}
                  </h2>
                  <div
                    :class="$vuetify.breakpoint.smAndDown ? 'text-xs' : ''"
                  >
                    <p class="mb-0">
                      {{ $t('faq.questionsAndAnswers') }}  {{ $t('faq.' + faqQuestions[selectedTab].title) }}
                    </p>
                  </div>
                </div>
              </div>
              <v-expansion-panels v-model="activeQuestion">
                <v-expansion-panel
                  v-for="item in faqQuestions[selectedTab].qaAndAnswer"
                  :key="item.question"
                  elevation="0"
                >
                  <!-- question -->
                  <v-expansion-panel-header>
                    <p
                      class="ma-0 pa-0"
                      style="line-height: 1.4;"
                      v-html="$t('faq.' + item.question)"
                    >
                    </p>
                  </v-expansion-panel-header>

                  <!-- answer -->
                  <v-expansion-panel-content
                    class="rounded-lg"
                  >
                    <p
                      v-html="$t('faq.' + item.answer)"
                    >
                    </p>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-window-item>
            <v-window-item>
            </v-window-item>
          </v-window>

          <!-- contact us card -->
          <v-row
            align="center"
            justify="center"
            :class="$vuetify.breakpoint.mdAndDown ? 'mt-0' : 'mt-10'"
          >
            <v-col
              cols="12"
              lg="6"
              md="8"
              sm="11"
              class="pb-15 pt-6"
            >
              <v-card
                class="justify-center align-center rounded-lg"
                flat
              >
                <v-card-text
                  class="align-end justify-end text-center pt-5"
                  style="word-break: break-word"
                >
                  <div class="mb-3">
                    <a href="mailto:support@anita-lee.com">
                      <v-avatar
                        size="50"
                        class="v-avatar-light-bg primary--text"
                      >
                        <v-icon
                          color="primary"
                          size="35"
                        >
                          {{ icons.mdiEmailOutline }}
                        </v-icon>
                      </v-avatar>
                    </a>
                  </div>
                  <div
                    style="word-break: break-word"
                    class="align-center justify-center text-center text-xl"
                  >
                    <span :class="$vuetify.breakpoint.smAndDown ? 'text-base font-weight-bold' : ''">
                      {{ $t('faq.stillNeedHelp') }}
                    </span>
                  </div>

                  <div>
                    <a
                      class="text-h6"
                      :href="`mailto:${contactUsEmail}`"
                    >
                      <span :class="$vuetify.breakpoint.smAndDown ? 'text-sm' : ''">
                        {{ contactUsEmail }}
                      </span>
                    </a>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import useContactUsDetails from '@/composables/useContactUsDetails'
import { i18n } from '@/plugins/i18n/index'
// eslint-disable-next-line object-curly-newline
import { mdiApps, mdiClose, mdiCreditCardOutline, mdiEmailOutline, mdiMessageProcessing, mdiPhone } from '@mdi/js'
import { ref, watch } from '@vue/composition-api'

export default {
  setup() {
    const selectedTab = ref(0)
    const activeQuestion = ref(-1)
    const { contactUsEmail } = useContactUsDetails()

    watch(selectedTab, () => {
      activeQuestion.value = 0
    })

    const faqQuestions = [
      {
        title: 'messages',
        icon: mdiMessageProcessing,
        qaAndAnswer: [
          {
            question: 'question_about_msg_flow',
            answer: 'answer_for_msg_flow',
          },
          {
            question: 'question_about_edit_msg_content',
            answer: 'answer_for_edit_msg_content',
          },
          {
            question: 'question_about_visibility_msg',
            answer: 'answer_for_visibility_msg',
          },
          {
            question: 'question_about_edit_msg_content_after_start_proccess',
            answer: 'answer_for_edit_edit_msg_content_after_start_proccess',
          },
          {
            question: 'question_about_sending_time_msg',
            answer: 'answer_for_sending_time_msg',
          },
        ],
      },
      {
        title: 'calls',
        icon: mdiPhone,
        qaAndAnswer: [
          {
            question: 'question_about_calls_flow',
            answer: 'answer_for_calls_flow',
          },
          {
            question: 'question_about_edit_calls_content',
            answer: 'answer_for_edit_calls_content',
          },
          {
            question: 'question_about_how_calls_listen',
            answer: 'answer_for_how_calls_listen',
          },
          {
            question: 'question_about_edit_calls_content_after_start_proccess',
            answer: 'answer_for_edit_edit_calls_content_after_start_proccess',
          },
          {
            question: 'question_about_sending_time_calls',
            answer: 'answer_for_sending_time_calls',
          },
        ],
      },
      {
        title: 'system',
        icon: mdiApps,
        qaAndAnswer: [
          {
            question: 'question_about_how_add_invitees',
            answer: 'answer_for_how_add_invitees',
          },
          {
            question: 'question_about_how_customize_invitees_settings',
            answer: 'answer_for_how_customize_invitees_settings',
          },
          {
            question: 'question_about_export_to_excel',
            answer: 'answer_for_export_to_excel',
          },
          {
            question: 'question_about_bulk_edit_invitees',
            answer: 'answer_for_bulk_edit_invitees',
          },
          {
            question: 'question_about_edit_invitee_status_manually',
            answer: 'answer_for_edit_invitee_status_manually',
          },
          {
            question: 'question_about_rsvp_status',
            answer: 'answer_for_rsvp_status',
          },
          {
            question: 'question_about_edit_start_rsvp',
            answer: 'answer_for_edit_start_rsvp',
          },
        ],
      },
      {
        title: 'payment',
        icon: mdiCreditCardOutline,
        qaAndAnswer: [
          {
            question: 'question_about_update_package',
            answer: 'answer_for_update_package',
          },
          {
            question: 'question_about_coupon_use',
            answer: 'answer_for_coupon_use',
          },
        ],
      },
    ]

    return {
      selectedTab,
      i18n,
      activeQuestion,
      faqQuestions,
      contactUsEmail,
      icons: {
        mdiEmailOutline,
        mdiClose,
      },
    }
  },
}
</script>
<style lang="scss" scoped>
@import '~vuetify/src/styles/styles.sass';

.faq-v-window {
  .v-window__container {
    z-index: 0;
  }
}

.gamification-john-pose-2 {
  position: absolute;
}

.gamification-john-pose-2 {
  bottom: 0;
  @include ltr() {
    right: 5%;
  }
  @include rtl() {
    left: 5%;
  }
}

@media (max-width: 1000px) {
  .gamification-john-pose-2 {
    max-width: 80px;
    @include ltr() {
      right: 2%;
    }
    @include rtl() {
      left: 2%;
    }
  }
}

@media (max-width: 500px) {
  .gamification-john-pose-2 {
    max-width: 70px;
  }
}

@media (max-width: 400px) {
  .gamification-john-pose-2 {
    display: none;
  }
  .page-title {
    font-size: 1.2rem !important;
  }
}
.v-tabs--vertical > .v-tabs-bar .v-tab {
  width: 100%;
}
</style>
